<template>
  <section class="category-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <div class="w-100">
        <b-form-group :label="$t('message.Firstname')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Firstname')"
              rules="required"
          >
            <b-input v-model="form.first_name" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.Lastname')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Lastname')"
              rules="required"
          >
            <b-input v-model="form.last_name" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.Middlename')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Middlename')"
              rules="required"
          >
            <b-input v-model="form.middle_name" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.Email')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Email')"
              rules="required"
          >
            <b-input v-model="form.email" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.Role')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Role')"
              rules="required"
          >
            <b-form-select :options="roles" text-field="display_name" value-field="id" v-model="form.role_id"></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div v-if="!id" class="w-100">
        <b-form-group :label="$t('message.Password')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Password')"
              rules="required"
          >
            <b-input type="password" v-model="form.password" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div v-else class="w-100">
        <b-form-group :label="$t('message.Password')">
            <b-input type="password" v-model="form.password" required></b-input>
        </b-form-group>
      </div>

    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('message.Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { storeUser, updateUser, showUser } from '@/api/users'
import {getRoles} from '@/api/users'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';

function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    first_name: get('first_name', null),
    last_name: get('last_name', null),
    middle_name: get('middle_name', null),
    email: get('email', null),
    password: get('password', null),
    role_id: get('role_id', null),
  }
}

const actions = {
  add:storeUser,
  update: updateUser,
  show: showUser,
}

export default {
  name: 'Form',
  components:{
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
      roles: [],
    }
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    reformatData() {
      this.form = {...this.form, nomination_id: this.$route.params.id};
      let form = this.prepareFormData(this.form);
      return form;
    },
    setForm(data) {
      this.form = initForm(data);
    },
    fetchRoles() {
      getRoles().then(res => {
        this.roles = res.data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>
