var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"category-form"},[_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}}),_c('validation-observer',{ref:"observer",staticClass:"w-100"},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Firstname')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Lastname')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Middlename')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Middlename'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.middle_name),callback:function ($$v) {_vm.$set(_vm.form, "middle_name", $$v)},expression:"form.middle_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Role')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.roles,"text-field":"display_name","value-field":"id"},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.id)?_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3025744513)})],1)],1):_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Password')}},[_c('b-input',{attrs:{"type":"password","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)]),_c('div',{staticClass:"w-100 mt-3 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.saveSubmit}},[_vm._v(_vm._s(_vm.$t('message.Add')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }