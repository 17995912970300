<template>
  <section class="users">
    <div class="w-100 mt-3">
      <custom-filter @handleSearch="handleSearch" @handleAdd="handleAdd">
        <template slot="customFilters">
          <b-col cols="2">
            <b-form-group>
              <b-form-select :options="roles" text-field="display_name" value-field="id" v-model="filters.role_id"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-select :options="school_types" text-field="name" value-field="id" v-model="filters.school_type_id"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-button variant="warning" @click="getClear">
              <b-icon icon="arrow-repeat"></b-icon>
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="info" @click="getSearch">
              <b-icon icon="search"></b-icon>
              {{$t('message.Search')}}
            </b-button>
          </b-col>
        </template>
      </custom-filter>
      <b-card>
        <b-table :items="datas" :fields="fields" responsive>
          <template #cell(avatar)="{item}">
            <b-avatar variant="info" :src="$backendHost+item.avatar"></b-avatar>
          </template>
          <template #cell(file)="{item}">
            <div v-if="item.profile">
              <a :href="$backendHost+item.profile.work_book_file" target="_blank" class="btn btn-outline-primary btn-sm">
                <b-icon icon="download"></b-icon>
              </a>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button variant="primary" size="sm" @click="handleEdit(data.item.id)" >
                <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button class="ml-1" variant="danger" size="sm" @click="handleDelete(data.item.id)" >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
              v-model="pagination.page"
              hide-goto-end-buttons
              :total-rows="total"
              :per-page="pagination.pageSize"
              @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal id="modal-lg" size="lg" hide-footer :title="modalTitle" v-model="modalVisible" >
      <Form :id="id" @saved="fetchList" />
    </b-modal>
  </section>
</template>

<script>
import { getUsers, deleteUser, getRoles } from '@/api/users'
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import Form from '@/views/users/Form'

const actions = {
  get: getUsers,
  delete: deleteUser,
}


export default {
  name: 'index',
  components:{
    CustomFilter,
    Form,
  },
  mixins:[
    listTemplate,
  ],
  created() {
    this.fetchRoles();
  },
  data() {
    return {
      actions,
      roles: [],
      school_types: [],
      fields: [
        {key:'id', label: this.$t('ID')},
        {key:'avatar', label: this.$t('Avatar')},
        {key:'first_name', label: this.$t('FirstName')},
        {key:'last_name', label: this.$t('LastName')},
        {key:'middle_name', label: this.$t('MiddleName')},
        {key:'email', label: this.$t('Email')},
        {key: 'role.display_name', label: this.$t('message.Role')},
        {key: 'profile.user_school_type.name', label: this.$t('message.SchoolType')},
        {key: 'profile.phone_number', label: this.$t('message.PhoneNumber')},
        {key:'file',label: this.$t('message.File')},
        {key:'action', label: this.$t('Action')},
      ],
      filters: {
        nomination_id: this.$route.params.id,
        role_id: null,
        school_type_id: null,
      }
    }
  },
  methods: {
    fetchRoles() {
      getRoles().then(res => {
        this.roles =  res.data.data.filter(item => item.name !== 'teacher');
        this.roles.unshift({id:null, display_name: this.$t('Role')})
        this.school_types = res.data.school_types;
        this.school_types.unshift({id: null, name: this.$t('message.SchoolType')})
      })
    },
    getSearch() {
      this.pagination.page = 1;
      this.fetchList()
    },
    getClear() {
      this.pagination.page = 1;
      this.filters.role_id = null;
      this.filters.search = null;
      this.fetchList()
    },
    handleSearch(search) {
      this.filters.search = search;
    }
  }
}
</script>

<style scoped>

</style>
